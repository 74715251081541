import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import Wrapper from '../../styles/utilities/Wrapper';
import Video from '../helpers/Video';
import HeadingContent, { HeadingContainer } from '../modules/HeadingContent';
import {
  colors,
  misc,
  spacing,
  breakpoints,
  font,
} from '../../styles/utilities/settings';
import BackgroundImage from '../helpers/BackgroundImage';
import { SiteContext } from '../Layout';

const HeroBanner = ({ mainImage, content, content: { headingContent } }) => {
  const [started, setStarted] = useState(false);
  const { transparentHeader } = useContext(SiteContext);

  return (
    <BackgroundImage
      logo={content.logo}
      hero
      src={mainImage}
      alt={headingContent.heading}
    >
      <SHeroBanner logo={content.logo} transparentHeader={transparentHeader}>
        {content.videoUrl && (
          <ReactPlayer
            url={content.videoUrl}
            loop
            playing
            value={0}
            muted
            controls={false}
            className={`videoBg${started ? ' active' : ''}`}
            height="100%"
            width="100%"
            onStart={() => {
              setStarted(true);
            }}
          />
        )}
        {content.video && <Video src={content.video} />}
        <Wrapper narrow>
          <HeadingContent hero logo={content.logo} content={headingContent} />
        </Wrapper>
      </SHeroBanner>
    </BackgroundImage>
  );
};

export default HeroBanner;

export const SHeroBanner = styled.div`
  padding: 100px 0;
  color: ${colors.beige};
  ${({ transparentHeader }) =>
    transparentHeader
      ? 'height: 100vh;'
      : `
        height: calc(100vh - ${spacing.headerHeight});
        @media screen and (max-width: ${breakpoints.ipadLand - 1}px) {
          height: auto;
          padding: 0;
        }
  `};
  ${({ logo }) =>
    logo &&
    `
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}

  ${({ logo }) =>
    !logo &&
    `
    ${Wrapper} {
      max-width: none;
      padding: 0;
    }
  `}

  .videoBg {
    position: absolute;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0 !important;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: 0;
    transition-duration: ${misc.animSpeed};

    &.active {
      opacity: 1;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }

  ${HeadingContainer} {
    ${({ logo }) =>
      logo
        ? `
        width: 85%;
        margin: 0 auto;
    `
        : `
      background-color: ${colors.blue};
      padding: 20px;

      @media screen and (min-width: ${breakpoints.ipadLand}px) {
        padding: 40px 80px;
        position: absolute;
        bottom: 156px;
        left: 0;
        max-width: 579px;
      }
    `}

    h1 {
      ${({ logo }) =>
        !logo
          ? `
        font-family: ${font.secondary};
        font-size: 25px;
        line-height: 1.3;
        letter-space: 0;

        @media screen and (max-width: ${breakpoints.ipadLand - 1}px) {
          font-size: 22px;
        }
        `
          : `
        text-transform: none;
        line-height: 1;
        margin-top: 30px;
      `}
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import PageLink from '../helpers/PageLink';
import { richTextContent, button } from '../../styles/utilities/elements';
import RichText from '../contentBlocks/RichText';
import imageUrlFor, { buildImageObj } from '../../js/imageUrlFor';

const HeadingContent = ({
  hero,
  logo,
  content: { heading, link, copy, user, date },
}) => (
  <HeadingContainer>
    {logo && <img src={imageUrlFor(buildImageObj(logo))} alt={heading} />}
    {hero ? <h1>{heading}</h1> : <h2>{heading}</h2>}
    {user && <p>{`by ${user}`}</p>}
    {date && <p>{`published on ${date}`}</p>}
    {copy && <RichText content={{ copy, centered: !hero }} />}
    {link && link.copy && <PageLink content={link} />}
  </HeadingContainer>
);

export default HeadingContent;

export const HeadingContainer = styled.div`
  position: relative;
  z-index: 1;
  ${richTextContent};

  > a {
    margin-top: 20px;
    ${button};
  }
`;

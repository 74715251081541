import React, { useState } from 'react';
import styled from 'styled-components';
import PageLink from '../helpers/PageLink';
import { breakpoints, colors } from '../../styles/utilities/settings';
import { absoluteCenter } from '../../styles/utilities/elements';
import { above, below } from '../../styles/utilities/mediaQueries';
import BackgroundImage, { SBackgroundImage } from '../helpers/BackgroundImage';
import SvgLoader from '../helpers/SvgLoader';
import YouTubeVideo from '../helpers/YouTubeVideo';
import RichText from './RichText';

// Single Feature Component
const SingleFeature = ({
  flip,
  contained,
  content: { video, image, heading, copy, link, iconSelector },
}) => {
  const [toggleVideo, setToggleVideo] = useState(false);
  return (
    <SFeature
      contained={contained}
      flip={flip}
      className={
        video && !toggleVideo ? 'video' : `${video && toggleVideo && 'active'}`
      }
    >
      <div className="image">
        {video ? (
          <>
            {toggleVideo ? (
              <YouTubeVideo title={heading} videoId={video} />
            ) : (
              <>
                <a
                  href={null}
                  onClick={() => {
                    setToggleVideo(true);
                  }}
                >
                  <SvgLoader name="play" color={colors.beige} />
                </a>
                <BackgroundImage
                  src={image}
                  maxWidth={breakpoints.pageWidth / 2}
                  alt={heading}
                />
              </>
            )}
          </>
        ) : (
          <BackgroundImage
            src={image}
            maxWidth={breakpoints.pageWidth / 2}
            alt={heading}
          />
        )}
      </div>
      <div className="content">
        {iconSelector && iconSelector.icon && (
          <SvgLoader
            name={iconSelector.icon}
            width={60}
            color={colors.darkBlue}
          />
        )}
        <h3>{heading}</h3>
        {copy && <RichText content={{ copy, centered: contained }} noWrapper />}
        {link && link.copy && (
          <PageLink
            style={{
              marginTop: '20px',
            }}
            content={link}
          />
        )}
      </div>
    </SFeature>
  );
};

// Single Feature Styles
export const SFeature = styled.div`
  background-color: ${colors.beige};
  position: relative;

  ${({ contained }) =>
    !contained &&
    `
    @media screen and (min-width: ${breakpoints.ipadLand}px) {
      padding: 120px 0;
    }
  `}

  ${above.ipadLand`
    display: flex;
    align-items: stretch;
    flex-direction: ${({ flip }) => (flip ? 'row-reverse' : 'row')};
  `}

  &:nth-child(2n) {
    flex-direction: ${({ flip }) => (flip ? 'row' : 'row-reverse')};
    background-color: ${colors.white};

    .content {
      svg {
        @media screen and (min-width: ${breakpoints.ipadLand}px) {
          left: 30px;
        }
      }
    }
  }

  &:nth-child(2n - 1) {
    .content {
      svg {
        @media screen and (min-width: ${breakpoints.ipadLand}px) {
          right: 30px;
        }
      }
    }
  }

  &.video {
    .image {
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: ${colors.blackOverlay};
        z-index: 2;
        display: block;
      }

      a {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &.active {
    align-items: center;
    .image {
      overflow: hidden;
    }
  }

  > div {
    ${above.ipadLand`
      width: 50%;
    `}
  }

  .image {
    position: relative;

    ${below.ipadLand`
      padding-bottom: 60%;
    `}

    ${SBackgroundImage} {
      height: 100%;
      width: 100%;
      @media screen and (max-width: ${breakpoints.ipadLand - 1}px) {
        position: absolute;
        top: 0;
        left: 0;

        > picture img {
          position: absolute !important;
          height: 100% !important;
        }
      }
    }

    svg {
      ${absoluteCenter};
      z-index: 3;
      opacity: 0.39;
    }
  }

  .content {
    ${({ contained }) =>
      contained
        ? `text-align: center;
        max-width: 490px;
        margin: 0 auto;
    `
        : `max-width: ${breakpoints.pageWidth / 1.2}px;`}
    padding: 50px ${({ contained }) => (contained ? '30px' : '100px')};
    font-size: 16px;
    line-height: 1.8;

    ${below.ipadLand`
      padding: 50px 15px;
    `}

    h3 {
      color: ${colors.darkBlue};
      font-size: 40px;
      margin-top: 10px;
    }

    > a {
      color: ${colors.red};
      display: block;
      font-weight: 600;
      font-size: 17px;
      text-transform: uppercase;
      text-decoration: none;
      margin-top: 10px;
    }
  }
`;

//* **************** *///
//* **************** *///

// Feature List Component
const ImageFeatures = ({ content: { features, contained, flipImageSide } }) =>
  features.map((feature) => (
    <SingleFeature
      contained={contained}
      key={feature._key}
      content={feature}
      flip={flipImageSide}
    />
  ));

export default ImageFeatures;

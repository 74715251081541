import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Wrapper from '../../styles/utilities/Wrapper';
import { breakpoints, colors } from '../../styles/utilities/settings';
import ImageSizer from '../helpers/ImageSizer';

const CurratedContent = ({ content }) => {
  const {
    allSanityPage: { pages },
    allSanityCornType: { cornTypes },
    allSanityCorn: { corn },
  } = useStaticQuery(graphql`
    {
      allSanityPage {
        pages: nodes {
          _id
          title
          _rawMainImage
          slug {
            current
          }
        }
      }
      allSanityCornType {
        cornTypes: nodes {
          _id
          title
          _rawMainImage
          slug {
            current
          }
        }
      }
      allSanityCorn {
        corn: nodes {
          _id
          title
          _rawImages
          slug {
            current
          }
        }
      }
    }
  `);

  return (
    <SCurratedContent>
      <Wrapper>
        <h3>{content.heading}</h3>
        <div>
          {content.posts.map(({ _ref }, index) =>
            [...pages, ...cornTypes, ...corn].map(
              (item) =>
                item._id === _ref && (
                  <ContentItem>
                    <Link key={item._id + index} to={item.slug.current}>
                      <div className="inner">
                        <ImageSizer
                          height={400}
                          width={250}
                          src={item._rawMainImage || item._rawImages[0]}
                          alt={item.title}
                        />
                        <h4>{item.title}</h4>
                      </div>
                    </Link>
                  </ContentItem>
                )
            )
          )}
        </div>
      </Wrapper>
    </SCurratedContent>
  );
};

export default CurratedContent;

const ContentItem = styled.div`
  a {
    position: relative;
    display: block;
    width: 100%;
  }

  h4 {
    position: absolute;
    top: 20px;
    left: -10px;
    padding: 5px 20px 3px;
    background-color: ${colors.blue};
    color: ${colors.darkBlue};
    z-index: 1;
    margin: 0;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      width: 0;
      height: 0;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      border-left: 16px solid ${colors.blue};
    }
  }
`;

const SCurratedContent = styled.div`
  position: relative;
  padding: 70px 0;

  &::before {
    content: '';
    display: block;
    background-color: ${colors.darkBlue};
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  ${Wrapper} {
    > div {
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        display: flex;
        justify-content: center;
      }

      ${ContentItem} {
        @media screen and (min-width: ${breakpoints.ipadPort}px) {
          padding: 0 25px;
          max-width: 33.333%;
        }

        + ${ContentItem} {
          @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
            margin-top: 50px;
          }
        }
      }
    }
  }

  .inner {
    position: relative;
    max-width: 100%;
    width: 250px;
    height: 400px;
    margin: 0 auto;
  }

  h3 {
    position: relative;
    color: ${colors.beige};
    font-size: 30px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 65px;
  }
`;
